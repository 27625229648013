<template>
  <div>
    <div>
      <el-row class="cc">
        <el-col :span="16" :offset="4">
          <div class="top_dh">
            <p class="top_navigation"><span @click="$router.push('index')">首页</span> &gt; <span @click="changeNav(1)">磐石产品</span> &gt; <span @click="changeNav(1)">磐石云桌面</span> &gt; <span>云桌面</span></p>
          </div>
          <p class="IntroductionTitle">概述</p>
        </el-col>
      </el-row>

      <el-row class="Introduction cw">
        <el-col :span="16" :offset="4" style="margin-bottom: 20px;">
          <div class="contectBox">
            <div class="contectimg">
              <!-- <img src="@/assets/panshProduct/cloudDesktop/a1.jpg" /> -->
              <img src="@/assets/panshProduct/cloudDesktop/yzm.png" />
            </div>
            <div class="IntroductionDesc" style="overflow:hidden;">
              <h6>云桌面</h6>
              <br>
              <p :style="{height:descShow ? 'auto' : '168px'}">
                云计算技术的发展正在改变着社会生产和人们的生活，面向云桌面及应用发布解决方案纷芸而出，满怀科技创新历史使命的众多高科技企业通过自己的努力为这个时代作出了卓越贡献，云上应用正在改变传统，按需交付即将主导未来。
                <br/>
                <br/>
                &emsp;&emsp;磐石云桌面通过统一管理平台 (控制引擎) 将虚拟的桌面组件 (包括应用软件、操作系统和用户数据等) 部署在云数据中心，进行统一管理、集中控制和按需发布。支持向PC、平板设备、笔记本电脑和云终端交付云桌面或应用，实现媲美传统PC及图形工作站的体验效果，既可以发布整个桌面系统，又支持单独发布应用。支持多种接入平台，有效降低基础设施投入和运维成本，提高应用安全和数据安全，重新定义云管模型，重塑工作方式，为用户打造更加灵活的应用交付模式和办公空间。
              </p>
            </div>
            <div @click="descShow = !descShow" class="rightbtn">
              <div v-show="descShow" style="cursor:pointer;margin-top: 24px;font-size: 14px;color: #e60012;">
                <span style="position: relative;top: -5px;right: 5px;">点击收起</span>
                <img src="@/assets/panshProduct/cloudDesktop/closeListmore.png" />
              </div>
              <div v-show="!descShow" style="cursor:pointer;margin-top: 24px;font-size: 14px;color: #e60012;">
                <span style="position: relative;top: -5px;right: 5px;">点击展开</span>
                <img src="@/assets/panshProduct/cloudDesktop/openListmore.png" />
              </div>
            </div>

          </div>

        </el-col>
      </el-row>
      <!--    产品架构-->
      <el-row class="cc">
        <el-col :span="16" :offset="4">
          <p class="IntroductionTitle mt">产品架构</p>
        </el-col>
        <el-col :span="16" :offset="4" v-for="(item,index) in proFramework" :key="index" class="frameworkList">
          <p style="text-align:justify !important;font-size: 16px;color: #888;line-height: 28px;width: 63%;margin-left:18%;display: block;text-indent: 2em;">
          磐石云桌面支持常见的终端设备，兼容多类型操作系统，集成磐石云桌面客户端，实现访问虚拟桌面或虚拟应用。同时支持内部网络（局域网、专线）、广域网（Internet、4G/5G、Wifi）方式接入，集成磐石盾“量子动态密钥”安全措施，保障接入安全。管理层包括用于交付桌面和应用的基础交付模块和高级模块。支持交付虚拟桌面和虚拟应用，支持将专业软件使用许可通过应用发布等组件以“许可服务”方式在云数据中心进行托管，并支持进行统一管理、集中控制和按需发布。主机层用于构建物理和虚拟桌面资源池，支持基于网络存储的传统虚拟化架构（Citrix Hypervisor、VMware vSphere），也支持超融合架构（VMware vSAN、SmartX、Nutanix、QingCloud等），同时支持基于云基础设施（阿里云、华为云平台）进行交付。
          </p>
          <div><img v-lazy="item.imgSrc" width="70%"></div>
        </el-col>
      </el-row>
  <!--产品功能-->
      <el-row>
          <el-col :span="16" :offset="4" style="padding-bottom:2%;">
            <p class="IntroductionTitle mt">产品功能</p>
            <div style="margin-top:2%;">
              <div v-for="(item,index) in proFunction" :key="index" class="proFunctionBox">
                <div class="proFunctionImg"><img v-lazy="item.imgSrc" width="90%"></div>
                <div class="proFunctionTitle">{{item.title}}</div>
                <div class="proFunctionDesc"><p>{{item.desc}}</p></div>
              </div>
            </div>
          </el-col>
      </el-row>
  <!--产品特点-->
      <el-row class="cc">
        <el-col :span="16" :offset="4">
          <p class="IntroductionTitle mt">产品特色</p>
          <div class="mt" style="margin-top:2%;">
            <div class="frameworkList" style="border-top-left-radius: 0;border-top-right-radius: 0;padding-top: 0">
              <div>
                <img src="@/assets/panshProduct/cloudDesktop/cpts.png" width="100%">
              </div>
              <div class="proChara">
                <ul>
                  <li>安全数据架构模型，数据不落地</li>
                  <li>磐石盾身份验证、动态量子、安全二维码</li>
                </ul>
                <ul>
                  <li>多模式交付，按需定制</li>
                  <li>虚拟应用资源交付，资源占用低</li>
                </ul><ul>
                  <li>媲美PC的用户体验</li>
                  <li>完美的软件兼容性</li>
                  <li>广泛的外设支持</li>
                  <li>成熟的vGPU支持</li>
                </ul><ul>
                  <li>统一控制引擎平台，管理、交付、监控于一体</li>
                  <li>定制化云端管理功能</li>
                  <li>移动管理</li>
                  <li>自动运维</li>
                </ul><ul>
                  <li>任意时间、任意地点，使用任意企业应用；</li>
                  <li>支持多种场景，适应多种环境</li>
                </ul><ul>
                  <li>全面适配麒麟、统信等国产操作系统；</li>
                  <li>深度融合nutanix、vmware、SmartX、青云等主流底层虚拟资源平台</li>
                </ul> 
                <div class="clearfix"></div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
  <!--    应用场景-->
      <el-row>
        <el-col :span="16" :offset="4">
          <p class="IntroductionTitle mt">应用场景</p>
          <div class="frameworkList">
            <img src="@/assets/panshProduct/cloudDesktop/yycj.png" />
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "cloudDesktop",
  data(){
    return{
      proFramework:[
        {title:'系统架构',imgSrc:require('@/assets/panshProduct/cloudDesktop/pic1.png'),},
      ],
      proFunction:[
        {title:'安全接入',desc:'通过多元安全认证、磐石盾、量子密钥等多种身份认证方式，保证用户登录安全，将用户数据集中存储，统一管理，保障数据永不丢失。',imgSrc:require('@/assets/panshProduct/cloudDesktop/l1.png'),},
        {title:'应用商店',desc:'统一用户资源看板，对托管资源站点的用户进行身份验证并管理已授权的桌面和应用程序，确保在多个设备之间向用户提供一致的体验。',imgSrc:require('@/assets/panshProduct/cloudDesktop/l9.png'),},
        {title:'控制引擎',desc:'中枢管理组件，由用于管理虚拟化资源、应用程序和桌面的服务组成，负责资源交付、用户授权、许可管理、资源监控及日志报表等。',imgSrc:require('@/assets/panshProduct/cloudDesktop/l3.png'),},
        {title:'多桌面形态',desc:'支持链接克隆、完整克隆以及流桌面置备多形态桌面发布，包括Windows、Linux以及麒麟、统信等国产操作系统。',imgSrc:require('@/assets/panshProduct/cloudDesktop/l5.png'),},
        {title:'监控运维',desc:'对物理及虚拟资源的集中管理、统一监控；全状态监控系统，从底层资源利用、会话接入性能等多个层面监控云桌面平台的使用性能情况。',imgSrc:require('@/assets/panshProduct/cloudDesktop/l4.png'),},
        {title:'全面兼容',desc:'全面兼容主流外接设备，通过策略灵活控制外设的重定向实现即插即用，包括打印机、高拍仪、Ukey、身份阅读器、指纹仪等。',imgSrc:require('@/assets/panshProduct/cloudDesktop/l6.png'),},
        {title:'多资源支持',desc:'传统虚拟化架构：Xen、Vmware；超融合架构：SmartX、Nutanix；公有云架构：青云、华为云',imgSrc:require('@/assets/panshProduct/cloudDesktop/l10.png'),},
        {title:'信创桌面',desc:'围绕信创规范，建构磐石生态，全面适配麒麟、统信等主流国产操作系统，通过适配动作持续优化产品形态和兼容模式。',imgSrc:require('@/assets/panshProduct/cloudDesktop/l11.png'),},
        {title:'vGPU控制',desc:'成熟的vGPU支持，通过图像策略优化以及编码技术，实现图形处理以及研发设计等场景的完美锲合。',imgSrc:require('@/assets/panshProduct/cloudDesktop/l12.png'),},
        {title:'窄宽带',desc:'通过ICA协议，适应多种网络条件，实现优质的低带宽条件下用户体验。',imgSrc:require('@/assets/panshProduct/cloudDesktop/l13.png'),},
        {title:'安全措施',desc:'提供统一服务地址和接入端口，与服务发布平台网络隔离，支持用户灵活访问，有效兼顾生产效率和安全举措。',imgSrc:require('@/assets/panshProduct/cloudDesktop/l14.png'),},
        {title:'硬件加速',desc:'网络加速和磁盘性能加速；支持多类型共享存储及分布式存储基础架构，实现低IOPS',imgSrc:require('@/assets/panshProduct/cloudDesktop/l2.png'),},
      ],
      yingyongcj:[
          {imgSrc: require('@/assets/panshProduct/cloudDesktop/dx1.png')},
          {imgSrc: require('@/assets/panshProduct/cloudDesktop/dx2.png')},
          {imgSrc: require('@/assets/panshProduct/cloudDesktop/dx3.png')},
          {imgSrc: require('@/assets/panshProduct/cloudDesktop/dx4.png')},
          {imgSrc: require('@/assets/panshProduct/cloudDesktop/dx5.png')},
          {imgSrc: require('@/assets/panshProduct/cloudDesktop/dx6.png')}
      ],
      descShow:false,
    }
  },
  created(){
    if (this._isMobile()) {
      // 跳转手机页面
      this.$router.push('cloudDesktopMobile')
    }
  },
  methods:{
    // 判断移动|PC端
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    },
    changeNav(navNum){
      this.$store.dispatch('changeShow',navNum)
    },
  }
}
</script>

<style scoped>

/* 通用样式 */
li{
  list-style: none;
}
.clearfix{
  clear: both;
}
/* 导航栏 */
.top_dh{
  margin-bottom: 20px;
}
.top_navigation{
  margin-top: 20px;
}
.top_navigation span{
  font-size: 16px;
  color: #666;
  cursor: pointer;
  transition: all 0.3s;
}
.top_navigation span:hover{
  color: #e40f0f;
}
.contectimg{
  width: 39.5%;
  float: left;
  margin-right: 5%;
  margin-bottom: 10px;
  cursor: pointer;
  position: relative;
}
.contectimg img{
  width: 100%;
}
/* 页面样式 */
/*产品概述*/
.cc{background: #f5f5f5;padding-bottom: 30px;}
.cw{background: #fff;}
.rightbtn{text-align: right;}
.mt{margin-top: 3%;}
.contectBox{
  margin-top: 30px;
}
.Introduction{
  position: relative;
}
.IntroductionTitle{
  border-left: 6px solid #e40f0f;
  line-height: 50px;
  font-size: 32px;
  padding-left: 30px;
}
.IntroductionDesc{
  font-size: 18px;
  color: #888;
  line-height: 31px;
}
.IntroductionDesc h6{
  font-size: 26px;
  line-height: 40px;
  font-weight: bold;
  color: #000;
}
.IntroductionDesc p{
  font-size: 16px;
  line-height: 28px;
  text-indent: 2em;
  text-align: justify;
}
/*产品架构区块*/
.currencyTitle{
  font-size: 48px;
  font-weight: bold;
  color: #101010;
  text-align: center;
  letter-spacing: 9px;
  padding: 56px 0 58px;
}
.frameworkList{
  text-align: center;
  background-color: #fff;
  margin-top:2%;
  padding-top: 2%;
  padding-bottom: 2%;
  /* margin: 0 12.5% 3% 12.5%;
  padding: 44px 0 48px 0; */
}
.frameworkList > img{
  width: 100%;
}
.currencyLittleTit{
  font-size: 26px;
  text-align: left;
  color: #101010;
  padding-left: 5%;
}
/*产品特点*/
.proChara{
  text-align: left;
  width: 97%;
  margin-left: 5.5%;
}
.proChara ul{
  width: 16.666%;
  display: block;
  float: left;
}
.proChara ul:first-child{
  width: 17%;
}
.proChara ul:nth-child(2){
  width: 19%;
}
.proChara ul:nth-child(3){
  width: 15.6%;
}
.proChara ul:nth-child(6){
  width: 14%;
}
.proChara ul:nth-child(5){
  width: 14.1%;
}

.proChara li{
  list-style: disc;
  line-height: 24px;
  padding-right: 16%;
  text-align: justify;
  color: #888;
}
/*产品功能*/
.proFunctionBox{
  background-color: #fff;
  width: 33.333%;
  float: left;
  padding-top: 3%;
  padding-bottom: 2%;
}
.proFunctionBox img{
  width: 84px;
  height: 80px;
}
.proFunctionTitle{
  font-size: 24px;
  color: #101010;
  line-height: 53px;
  text-align: center;
}
.proFunctionDesc p{
  font-size: 16px;
  color: #888;
  line-height: 24px;
  height: 120px;
  padding: 5% 20%;
  text-align: justify;
  white-space: break-spaces;
}
.proFunctionImg{
  text-align: center;
}

@media screen and (min-width: 1000px){
  
}
@media screen and (min-width: 1450px){
  .proChara li{line-height: 30px;}
}
@media screen and (min-width: 1560px){
}
</style>